.page {
  background-image: url('../../assets/onboardng-background.png');
  min-height: 100vh;
  background-size: cover;
}

.searchMagnifyingGlassIcon {
  background-image: url('../../assets/search-magnifying-glass.png');
  width: 52px;
  height: 52px;
}

.errorInfoContainer {
  background-color: #f7f7f7;
  padding: 52px 82px;
}
