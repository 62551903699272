a:-webkit-any-link {
    text-decoration: none;
    color: inherit;
  }
img {
  max-width: 100px;
}
.logo {
  width: 2rem;
  height: 2rem;
}