.box {
  background-color: #eeeeee;
  height: 94px;
  width: 100%;
}

.policies {
  font-weight: 400 !important;
  letter-spacing: 1px !important;
  text-transform: uppercase;
  font-size: 12px !important;
}

.copyright {
  font-weight: 500 !important;
  font-size: 14px;
}
