.brand {
    width: 10rem;
    height: auto;
}
.select {
    width: 10rem;
    height: auto;
}
.icon {
    color: rgba(0, 0, 0, 0.54);
}