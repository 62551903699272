body {
  margin: 0;
  @font-face {
    font-family: 'Archivo';
    src: local('Archivo'), url('./fonts/archivo.ttf') format('truetype');
    font-weight: normal;
  }
}

.react-datepicker__aria-live {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.quill .ql-editor {
  font-family: 'Archivo', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
}

.modebar-container {
  display: none;
}

.Toastify {
  z-index: 999999999;
  position: relative;
}
